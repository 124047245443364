import React, { useState, useEffect } from 'react';
import styled from 'styled-components'
import { Icon } from '../components';

import './useCursor.scss'

const cursorShapes = ['game', 'lightbulb', 'ping-pong', 'seedling', 'knife-blood', 'meteor', 'ghost', 'robot', 'skull', 'thumb-up']

const random = (arr) => {
    const rand = Math.floor(Math.random() * arr.length);
    return arr[rand]
}

const Cursor = styled.div`
    position: fixed;
    top: ${ ({ coords }) => coords.top + 'px' || 0 };
    left: ${ ({ coords }) => coords.left + 'px' || 0 };
`

const useCursor = (enable = true) => {
    const [ cursorPosition, setCursorPosition ] = useState()
    const [ cursorShape, setCursorShape ] = useState()
    
    // follow mouse movement and replace by a div
    useEffect(() => {
        if (enable) {
            document.body.classList.add('cursor--enabled')
            
            setCursorShape(random(cursorShapes))
            
            const handleMouseMove = (e) => {
                const { clientX, clientY } = e
                setCursorPosition({ top: clientY, left: clientX })
            }
            
            document.addEventListener('mouseover', handleMouseMove);
            window.addEventListener('mousemove', handleMouseMove);
            
            return () => {
                document.removeEventListener('mouseover', handleMouseMove);
                window.removeEventListener('mousemove', handleMouseMove);
            }
        }
    }, [])
    
    return () => <>
        { cursorPosition && (<>
            <Cursor coords={ cursorPosition }>
                <Icon name={ cursorShape } />
            </Cursor>
        </>
        )}
    </> 
}

export default useCursor