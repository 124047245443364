import React from 'react';
import { className } from '../../utils';
import styled from 'styled-components'

const Icon = styled.i.attrs(
        ({ 
            name = 'admin', 
            style = 'line', 
            className: cls 
        }) => ({ 
            ...className(`ri-${ name }${ style ? '-' : ''}${ style ? style : '' }`, cls)
        })
    )`
        color: white;
        font-size: ${ props => props.size || '1.4rem' };
        font-weight: normal !important;
    `

export default Icon