import React from 'react'
import { ReactComponent as LogoCompact } from '../../logos/compact.svg'
// import { ReactComponent as LogoHorizon } from './logos/horizontal.svg'
import { ReactComponent as LogoHinged } from '../../logos/hinged.svg'
import { Icon } from '../../components'

const ComingPage = () => {    
    const handleTakeoff = () => window.open('mailto:weare@jung.gent?subject=Howdy 🙋‍♂️')
    const handleTheGram = () => window.open('https://www.instagram.com/jung.gent')
    const handleTickle = () => alert('That tickles!');
    
    return (
        <div className="h-full flex flex-col items-center justify-center">
            <LogoHinged className="h-8 fill-zinc-100 opacity-80 mb-4" />
            <h3 className="font-light text-2xl uppercase tracking-widest text-center leading-7 text-zinc-100">Something's<br />coming</h3>
        </div>
    );
}

export default ComingPage;
