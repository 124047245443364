import { useEffect, useState } from 'react'
import { ReactComponent as LogoCompact } from './logos/compact.svg'
// import { ReactComponent as LogoHorizon } from './logos/horizontal.svg'
import { ReactComponent as LogoHinged } from './logos/hinged.svg'
import { Icon } from './components'
import { useCursor } from './hooks'
import { ComingPage } from './pages'

function App() {
    const Cursor = useCursor(false); 
    
    const handleTakeoff = () => window.open('mailto:weare@jung.gent?subject=Howdy 🙋‍♂️')
    const handleTheGram = () => window.open('https://www.instagram.com/jung.gent')
    const handleTickle = () => alert('That tickles!');
    
    return (
        <>
            <div className="fixed top-1/2 -translate-y-1/2">  
                <LogoCompact className="min-w-[100vw] min-h-[100vh] animate-[spin_480s_linear_infinite] fill-zinc-100 opacity-5" />
            </div>
            <div className="h-full p-8 flex flex-col select-none bg-zinc-900 overflow-scroll">
                <div className="flex-1">
                    
                    <div className="">
                        <div className="p-8">
                            <h2 className="text-7xl font-thin text-white">Sweet web snacks</h2>
                        </div>
                        <ComingPage />
                    </div>
                </div>
                <div className="z-10">
                    <div className="border-2 border-white border-opacity-0 md:hover:border-opacity-40 transition-all duration-300 w-fit rounded-xl pt-1 pb-0.5 px-3 flex">
                        <button onClick={ handleTakeoff } className="flex items-center justify-center mr-4"> 
                            <Icon name="send-plane"  />
                        </button>
                        <button onClick={ handleTheGram } className="flex items-center justify-center mr-3"> 
                            <Icon name="instagram"  />
                        </button>
                        <button onClick={ handleTickle } className="flex items-center justify-center">  
                            <Icon name="cactus" />
                        </button>
                    </div>
                </div>
            </div>
            <Cursor />
        </>
    );
}

export default App;
